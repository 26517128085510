import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Injectable, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { HppTransService } from "./hpp.trans.service";

@Injectable()
export class HppTransGuard implements CanActivate, OnInit {
  public loader$ = new Subject<boolean>();
  public loader = false;


  constructor(private hppTranService: HppTransService, private router: Router) { 

  }

  ngOnInit() {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    this.loader$.next(true);
    let params = route.params;
    if (this.hppTranService.getIsValidSessId()) {      
      this.loader$.next(false);
      return true;
    }
    if (params.sessId) {
      return this.hppTranService.getHppTrans(params.sessId)
        .pipe(
          map((response: any) => {
            const hppTransObj = response.hppTrans;
            if (Object.keys(hppTransObj).length == 0) {
              console.log(response);
              this.hppTranService.setHppError(response.message)
              this.loader$.next(false);
              this.router.navigate(["/payment/checkout-error"]);
              return false;
            }
            this.loader$.next(false);
            this.hppTranService.setData(hppTransObj);
            //if(!hppTransObj.isInvoice || !hppTransObj.isVterminal)
              //this.hppTranService.setAuthTimer(hppTransObj.sessionId);
            return true;
          },
            error => {
              console.log(error);
              this.router.navigate(['/payment/checkout-error']);
              this.loader$.next(false);
              return false;
            })
        );
    } else {
      this.router.navigate(['/payment/checkout-error']);
      return false;
    }
  }
}
