declare var require: any;
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkout-error',
  templateUrl: './checkout.error.component.html'
})
export class CheckoutErrorComponent implements OnInit {
  imgname = require("../../../assets/images/caymangatewaylogo340.png");

  constructor() { }

  ngOnInit() {
  }

}
