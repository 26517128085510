import { NgModule } from "@angular/core";
import {
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatIconModule,
  MatGridListModule,
  MatDividerModule,
  MatSelectModule,
  MatCheckboxModule
} from "@angular/material";

@NgModule({
  exports: [
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatSelectModule,
    MatCheckboxModule
  ]
})
export class AngularMaterialModule {}
