import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

import { environment } from "../../environments/environment";

const BACKEND_URL = environment.apiUrl + "hpp/cacheckout/";

@Injectable({ providedIn: "root" })
export class HppCaTransService {
  private isValidRefId = false;
  private hppCaTransStatusListener = new Subject<boolean>();
  private hppCaTransData: any;
  private hppError: any;
  private orginalCaTransData: any;
  private transactionTimer: any;
  private inActiveTime: number = environment.inactiveTime;

  constructor(private http: HttpClient, private router: Router) {}

  getIsValidRefId() {
    return this.isValidRefId;
  }

  getHppCaTransData() {
    return this.hppCaTransData;
  }

  getHppError() {
    return this.hppError;
  }

  getOrginalCaTransData() {
    return this.orginalCaTransData;
  }

  setOrginalCaTransData(trans:any) {
    this.orginalCaTransData = trans;
    this.isValidRefId = true;
  }

  setHppError(err:any) {
    this.hppError = err;
  }

  getHppCaTransStatusListener() {
    return this.hppCaTransStatusListener.asObservable();
  } 

  getHppCaTrans(clientRefId: string) {
    return this.http
      .get(
        BACKEND_URL + clientRefId,
      )
  }

  clearHppTrans(refId) {
    this.isValidRefId = false;
    this.hppCaTransData = {};
    this.hppCaTransStatusListener.next(false);
    clearTimeout(this.transactionTimer);
    this.http.get(BACKEND_URL + refId + '?timeout=1')
    .subscribe((response: any)=>{
       if (response.success) this.router.navigate(["/payment/checkout-inactive"]);
    });
  }

  public setAuthTimer(refId) {
    let duration = this.inActiveTime;
    console.log("Setting timer: " + duration);
    this.transactionTimer = setTimeout(() => {
      this.clearHppTrans(refId);
    }, duration * 1000);
  }

  public setData(hppCaTrans) {    
    this.hppCaTransData = hppCaTrans;
    this.isValidRefId = true;
  }
}
