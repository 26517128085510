declare var require: any;
import { Component, OnInit } from '@angular/core';
import { HppTransService } from "../hpp.trans.service";


@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.css']
})
export class AuthErrorComponent implements OnInit {
  imgname = require("../../../assets/images/caymangatewaylogo340.png");
  err:any;

  constructor(public hppTransService: HppTransService) { }

  ngOnInit() {
    this.err = this.hppTransService.getHppError();
  }

}
