declare var require: any;
import { Component, OnInit } from '@angular/core';
import { HppCaTransService } from 'src/app/payment/hpp.catrans.service';

@Component({
  selector: 'app-transaction-caconfirm',
  templateUrl: './checkout.caconfirm.component.html'
})
export class CheckoutCaConfirmComponent implements OnInit {

  imgname = require("../../../assets/images/caymangatewaylogo340.png");
  transData: any;
  transid: string;
  hppTransData: any;
  returnUrl: string;
  transRespMsg: string;
  transType: string;
  transResponse: string = '';
  errorQueryString: string = '';
  routeLink: any;

  constructor(public hppCaTransService: HppCaTransService) { }

  ngOnInit() {
    this.transData = this.hppCaTransService.getOrginalCaTransData();
    console.log(this.hppTransData);
    console.log(this.transData);

    if (this.transData.responseCode == "00" || this.transData.responseCode == "000") {
      this.transRespMsg = 'Thank you for your payment';
    } else {
      this.transRespMsg = 'There is an error with your transaction';
      this.errorQueryString = '&error=true';
    }

    this.transid = this.transData.transactionId;
    switch (this.transData.transactionType) {
      case 'sale':
        this.transType = 'Sale';
        break;
      case 'add-customer':
        this.transType = 'Vault';
        break;
      case 'update-customer':
        this.transType = 'Update Vault';
        break;
    }

    switch (this.transData.responseReasonText) {
      case 'Service Not Allowed':
        this.transResponse = 'The card type used is not supported by merchant. Please use a different card and try again.';
        break;
      case 'Declined':
        this.transResponse = 'Transaction is declined . please try again.';
        break;
      case 'Account Number not on file':
        this.transResponse = 'Declined. Please check your card details and try again.';
        break;
      case 'CVV2 Declined':
        this.transResponse = 'Declined. Please check your CVV and try again.';
        break;
      case 'Expired Card':
      case 'DOB Error':
        this.transResponse = 'Declined. Please check your expiry date and try again.';
        break;
      case 'Invalid amount':
      case 'Invalid Transaction':
      case 'Format Error':
      case '':
        this.transResponse = 'Declined. Please try again.';
        break;
      case 'Transaction is approved':
        this.transResponse = '';
        break;
      default:
        this.transResponse = 'Transaction is not succesfull. Please try again.';
        break;
    }
    this.routeLink = '/payment/checkout/'+this.transData.sessionId;

    if(this.transData.isInvoice) this.transType = "Invoice Sale";
    if(this.transData.isVterminal) {
      this.transType = "Virtual Terminal Sale";
      this.returnUrl = this.transData.returnUrl;
    }

    if(!this.transData.isRetryAllowed) {
      switch (this.transData.responseReasonText) {
        case 'Service Not Allowed':
          this.transResponse = 'The card type used is not supported by merchant. Transaction is not succesfull.';
          break;
        case 'Declined':
          this.transResponse = 'Transaction is declined.';
          break;
        case 'Account Number not on file':
          this.transResponse = 'Transaction is declined.';
          break;
        case 'CVV2 Declined':
          this.transResponse = 'Declined. Please check your CVV.';
          break;
        case 'Expired Card':
        case 'DOB Error':
          this.transResponse = 'Declined. Please check your expiry date.';
          break;
        case 'Invalid amount':
        case 'Invalid Transaction':
        case 'Format Error':
        case '':
          this.transResponse = 'Transaction is Declined.';
          break;
        case 'Transaction is approved':
          this.transResponse = '';
          break;
        default:
          this.transResponse = 'Transaction is not succesfull.';
          break;
      }
    }
    this.returnUrl = this.transData.returnUrl;
    /*setTimeout(() => {
      if(!this.transResponse)
        window.location.href = this.returnUrl;
    }, 5000);*/
  }

  close(){
    var win = window.open("","_self"); /* url = “” or “about:blank”; target=”_self” */
    win.close();
  }

}
