import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";

import { ErrorComponent } from "./error/error.component";
import { ErrorService } from "./error/error.service";
import { HppTransGuard } from "./payment/hpp.trans.guard";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog, private errorService: ErrorService,
    
    private hppTransGuard: HppTransGuard, 
    private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.hppTransGuard.loader$.next(false);
        let errorMessage = "An unknown error occurred!";
        if (error.error.message) {
          errorMessage = error.error.message;          
        }
        if (error.error.errors) {
          errorMessage = error.error.errors;          
        }
        let dialogRef = this.dialog.open(ErrorComponent, {data: {message: errorMessage}});
        dialogRef.afterClosed().subscribe(result => {
          this.router.navigate(['/payment/error']);
        });
        
        this.errorService.throwError(errorMessage);
        return throwError(error);
      })
    );
  }
}
