import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CheckoutComponent } from './payment/checkout/checkout.component';
import { AuthErrorComponent } from './payment/auth-error/auth-error.component';
import { TransactionConfirmComponent } from './transaction/transaction-confirm/transaction-confirm.component';


import { HppTransGuard } from "./payment/hpp.trans.guard";
import { HppCaTransGuard } from "./payment/hpp.catrans.guard";
import { TransactionFailureComponent } from "./transaction/transaction-failure/transaction-failure.component";
import { InactiveComponent } from "./payment/in-active/inactive.component";
import { CheckoutErrorComponent } from "./payment/checkout-error/checkout.error.component";
import { CheckoutCaConfirmComponent } from "./payment/checkout-caconfirm/checkout.caconfirm.component";

const routes: Routes = [
  { path: "", redirectTo: '/payment/error', pathMatch: 'full'},
  { path: "payment/checkout-confim/:sessId", component: TransactionConfirmComponent, canActivate: [HppTransGuard] },
  { path: "payment/fail/:sessId", component: TransactionFailureComponent, canActivate: [HppTransGuard] },
  { path: "payment/checkout/:sessId", component: CheckoutComponent, canActivate: [HppTransGuard]},
  { path: "payment/checkout-error", component: AuthErrorComponent},
  { path: "payment/error", component: CheckoutErrorComponent},
  { path: "payment/confirmpage/:clientRefId", component: CheckoutCaConfirmComponent, canActivate: [HppCaTransGuard]},
  { path: "payment/checkout-inactive", component: InactiveComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [HppTransGuard]
})
export class AppRoutingModule {}
