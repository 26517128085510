declare var require: any;
declare var Cardinal:any;
declare var ka:any;
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';


import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, ValidationErrors, AbstractControl  } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { MatIconRegistry } from '@angular/material/icon';

import * as countriesData from "../../../countries.json";

import { map } from "rxjs/operators";

import { CreditCardValidator, CreditCard } from 'angular-cc-library';
import { DomSanitizer } from '@angular/platform-browser';

export interface Country {
  abbreviation: string;
  country: string;
  states:any;
  code:any;
}


import { HppTransService } from "../hpp.trans.service";
import { HppTransData } from '../hpp.trans-data.model';
import { TransactionService } from '../../shared/services/transaction.service.js';
import { Router } from '@angular/router';

export function getAllowedCards(trans: any): ValidatorFn {
 
  return (control: AbstractControl): ValidationErrors | null => {
    
    let num: any = +control.value.toString().replace(/\s+|-/g, '');
    let cardType = CreditCard.cardType(num);
    if(!cardType) return null;
    switch (cardType) {
      case 'discover':
        if(!trans.isDiscoverEnabled)
          return { 'getAllowedCards': true, 'card': cardType }
        break;
      case 'amex':
        if(!trans.isAmexEnabled)
          return { 'getAllowedCards': true, 'card': cardType }
        break;
    }
    return null;
    
  } 
}


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  hppTrans: HppTransData;
  creditCardIcon = '';
  countries: Country[] = Object.values(countriesData);
  countryList = Object.values(countriesData)[0];
  selected = 'KY';
  termsChecked = false;
  imgname = require("../../../assets/images/caymangatewaylogo340.png");
  cardsimg = require("../../../assets/images/accepted_c22e0.png");
  visaimg = require("../../../assets/images/cards/visa.png");
  ameximg = require("../../../assets/images/cards/amex.png");
  mastercardimg = require("../../../assets/images/cards/mastercard.png");
  discoverimg = require("../../../assets/images/cards/discover.png");
  mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  showLoader: boolean = false;
  paymentForm: FormGroup;
  processMsg: string = "Payment is processing";
  isSubmit: boolean = false;
  isStateDropDown: boolean = false;
  paymentSetupData: any;
  paymentValidatedData: any;
  headerLogoUrl: any;
  termsUrl: any;
  poweredByText: any;
  customFields: any;
  kountClient: any;
  recurringInfoData:any;
  states:any;
  kountSessionId:string;
  myFormValueChanges$;
  ccnum:any;
  expMonth:any;
  expYear:any;
  cvv:any;
  @ViewChild('paymentAddForm') paymentAddForm1;

  constructor(public hppTransService: HppTransService,
    private http: HttpClient, iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private transService: TransactionService,
    private router: Router,
    private renderer: Renderer2
  ) {

    iconRegistry.addSvgIcon(
      'visa-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/flat/visa.svg'));

    iconRegistry.addSvgIcon(
      'amex-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/flat/amex.svg'));

    iconRegistry.addSvgIcon(
      'mastercard-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/flat/mastercard.svg'));

    iconRegistry.addSvgIcon(
      'discover-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/flat/discover.svg'));

    iconRegistry.addSvgIcon(
      'default-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/mono/default.svg'));

    iconRegistry.addSvgIcon(
      'security-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/mono/security-code.svg'));

  }

  ngOnInit() {
    this.hppTrans = this.hppTransService.getHppTransData();
    this.paymentForm = this.formBuilder.group({
      firstname: new FormControl(this.hppTrans.firstName, [Validators.required]),
      email: new FormControl(this.hppTrans.email, [Validators.email]),
      phone: new FormControl(this.hppTrans.phone ? this.hppTrans.phone : '', [<any>Validators.minLength(10), <any>Validators.maxLength(17)]),
      terms: new FormControl(null, [Validators.pattern('true')]),
      lastname: new FormControl(this.hppTrans.lastName, []),
      address1: new FormControl(this.hppTrans.street1, [Validators.required]),
      _csrf: new FormControl(null),
      sessId: new FormControl(null),
      address2: new FormControl(this.hppTrans.street2, []),
      city: new FormControl(this.hppTrans.city, [Validators.required]),
      state: new FormControl(this.hppTrans.state, []),
      postal: new FormControl(this.hppTrans.zip, [Validators.required, Validators.pattern("^[A-Z0-9a-z -]*$"), <any>Validators.maxLength(10)]),
      country: new FormControl(this.hppTrans.country ? this.hppTrans.country : this.selected, [Validators.required]),
      creditCard: new FormControl('', [<any>CreditCardValidator.validateCCNumber, getAllowedCards(this.hppTrans)]),
      expirationDate: new FormControl('', [<any>CreditCardValidator.validateExpDate]),
      cvc: new FormControl('', [<any>Validators.required, <any>Validators.minLength(3), <any>Validators.maxLength(4), Validators.pattern("^[0-9]*$")]),
    });

    if(this.hppTrans.country == 'US' || this.hppTrans.country == 'JP' || this.hppTrans.country == 'CA' || this.hppTrans.country == 'AU') {
      this.isStateDropDown = true;
      const countryList = this.countryList;
      let country = countryList.find(c => c.abbreviation == this.hppTrans.country);
      this.states = country.states;
      let hasStateVal = this.states.find(s => s.name == this.hppTrans.state || s.abbreviation == this.hppTrans.state);
      if(hasStateVal) {
        this.paymentForm.patchValue({
          state: hasStateVal.abbreviation
        });
      } else {
        this.paymentForm.patchValue({
          state: ''
        });
      }
      this.paymentForm.get('state').setValidators([Validators.required]);
      this.paymentForm.get('state').updateValueAndValidity();
    } else {
      this.isStateDropDown = false;
      this.states = [];
      this.paymentForm.get('state').setValidators([]);
      this.paymentForm.get('state').updateValueAndValidity();
    }

    if (this.hppTrans.is3dEnable) {
      this.renderExternalScript(this.hppTrans.threeDSScriptUrl).onload = () => {
        Cardinal.configure({
          logging: {
              level: "on"
          },
          payment:{
            view: 'inline'
          }
        });
        Cardinal.setup("init", {
          jwt: this.hppTrans.jwt
        });
        this.getPaymentSetupData();
      };
    }

    if(this.hppTrans.isKountEnabled) {
      this.renderExternalScript(this.hppTrans.kountScriptUrl+'?m='+this.hppTrans.kountMerchantId).onload = () => {
              this.kountClient=new ka.ClientSDK();
              this.kountClient.autoLoadEvents();
              this.paymentForm.addControl('kountSessionId',  new FormControl(ka.sessionId, []));
        };
    }
    this.headerLogoUrl = this.hppTrans.headerlogoUrl ? this.hppTrans.headerlogoUrl.trim() : ''; 
    this.termsUrl = this.hppTrans.termsUrl ? this.hppTrans.termsUrl.trim() : ''; 
    this.poweredByText = this.hppTrans.poweredbyText ? this.truncate(this.hppTrans.poweredbyText,43,'..') : '';
    if(this.hppTrans.isGGSHPPLogo) {
      this.imgname = require("../../../assets/images/GGS.png");
    }
    //add custom Fields
    if(this.hppTrans.isPartialAmountAllowed && this.hppTrans.isInvoice) {
      this.paymentForm.addControl('transactionAmount',  new FormControl(this.hppTrans.amount, [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"), <any>Validators.maxLength(20)]),);
    }
    if(this.hppTrans.customFieldEnabled) {
      
      if(this.hppTrans.customFieldsData && this.hppTrans.customFieldsData.length > 0){
        let customFields = this.hppTrans.customFieldsData.map(c => {
          c.groupFieldsData = JSON.parse(c.groupFieldsData);
          c.groupFieldsData = c.groupFieldsData.map(e => {
            if(e.type=="select"){
              e.value = e.value.split(",").map(item => item.trim());
            }
            return e;
          })
          return c;
        })
        this.customFields = customFields;
        let customGroup:any = {};
        this.customFields.forEach((data, idx) =>  {
          let group = {};
          if(data.groupFieldsData.length > 0) {
            data.groupFieldsData.forEach((groupData, i) => {
              let validators = [];
              if(groupData.required=="yes")
                validators.push(Validators.required)
              switch(groupData.validations) {
                case "alphanumaric":
                  validators.push(Validators.pattern("^[A-Za-z0-9]*$"));
                  break;
                case "alphanumaricSpaceHyphen":
                  validators.push(Validators.pattern("^[A-Za-z0-9 -]*$"));
                  break;
                case "numbersonly":
                  validators.push(Validators.pattern("^[0-9 ]*$"));
                  break;
                case "alphaonly":
                  validators.push(Validators.pattern("^[A-Za-z ]*$"));
                  break;
                default:
                  break;
              }
              let valueData;
              if(this.hppTrans.customFields && this.hppTrans.customFields.length > 0) {
                let customFieldsInfo = this.hppTrans.customFields.find(d => d.groupName == data.groupName);
                if(customFieldsInfo && customFieldsInfo.groupFields.length > 0){
                  valueData = customFieldsInfo.groupFields.find(val => val.name == groupData.label);
                }
              }
              let formVal = (valueData) ? valueData.value : '';
              group[groupData.label]=new FormControl(formVal,validators);
            });
            group['groupName'] = new FormControl(data.groupName);
            let customFieldsFormGroup:FormGroup = new FormGroup(group);
            //this.vTerminalForm.addControl('customFields'+(idx+1),  customFieldsFormGroup);
	    //customGroup['customFields'+(idx+1)] = customFieldsFormGroup;
	    if(this.hppTrans.customFields && this.hppTrans.customFields.length > 0) {
  		data.display = false;
  		let customFieldsInfo = this.hppTrans.customFields.find(d => d.groupName == data.groupName);
  		if(customFieldsInfo && customFieldsInfo.hasOwnProperty('groupDisplay') && customFieldsInfo.groupDisplay == 'true'){
			customGroup['customFields'+(idx+1)] = customFieldsFormGroup;
			data.display = true;
  		}
  		if(customFieldsInfo && !customFieldsInfo.hasOwnProperty('groupDisplay')){
			customGroup['customFields'+(idx+1)] = customFieldsFormGroup;
			data.display = true;
  		}
	} else {
	  data.display = true;
  		customGroup['customFields'+(idx+1)] = customFieldsFormGroup;
	}
          }
        });
        let custGrp:FormGroup = new FormGroup(customGroup);
        this.paymentForm.addControl('customFields',  custGrp);
      }
    }
    if(this.hppTrans.hppVTSubscriptionInfo) {
      this.recurringInfoData =  JSON.parse(this.hppTrans.hppVTSubscriptionInfo);
      if(this.hppTrans.isInvoice){
        this.recurringInfoData.paymentStartDate =  new Date();
        let numberOfMonths = 0;
        let numberofDays = 0;
        let newBillingEndDate = null;
        if(this.recurringInfoData.frequency != ''){
            switch(this.recurringInfoData.frequency){
                case 'months':
                    numberOfMonths = 1;
                    break;
                case 'days':
                    numberofDays = 1;
                    break;
                case 'years':
                    numberOfMonths = 12;
                    break;
                case 'weeks':
                    numberofDays = 7;
                    break;
                default:
                    numberOfMonths = 0;
                    numberofDays = 0;
                    break;
            }

            let billingEndDate = new Date();
            if(numberOfMonths){
                newBillingEndDate = new Date(billingEndDate.setMonth(billingEndDate.getMonth() + (numberOfMonths * this.recurringInfoData.frequencyNumber * (this.recurringInfoData.noOfPayments - 1))));
                newBillingEndDate.setDate(billingEndDate.getDate());
            } else if(numberofDays){
                newBillingEndDate = new Date(billingEndDate.setDate(billingEndDate.getDate()+(numberofDays * this.recurringInfoData.frequencyNumber * (this.recurringInfoData.noOfPayments - 1))));
            }
            this.recurringInfoData.paymentEndDate = newBillingEndDate;
          }
      }
    }

    //add chargeanywher form
    this.myFormValueChanges$ = this.paymentForm.valueChanges;
    this.myFormValueChanges$.subscribe(units => this.updateValues(units));
    //add chargeanywher form
  }

  public updateValues(units: any) {
    this.ccnum = units.creditCard.replace(/\D+/g, ''); ;
    let expirationDate = units.expirationDate.replace(/\D+/g, '');
    this.expMonth = expirationDate.slice(0,2);
    this.expYear = expirationDate.slice(2,4);
    this.cvv = units.cvc;
  }

   truncate(string, length, delimiter) {
    delimiter = delimiter || "&hellip;";
    return string.length > length ? string.substr(0, length) + delimiter : string;
  }

  async getPaymentSetupData() {
    if(!this.paymentSetupData)
      this.paymentSetupData = await this.paymentSetup();
  }

  paymentSetup() {
    return new Promise(resolve => {
      Cardinal.on('payments.setupComplete', function(setupCompleteData){
        resolve(setupCompleteData);
      });
    });
  }

  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  updateCCIcon(event: any) {
    if (event.target.classList.contains('visa')) this.creditCardIcon = 'visa-icon';
    if (event.target.classList.contains('mastercard')) this.creditCardIcon = 'mastercard-icon';
    if (event.target.classList.contains('amex')) this.creditCardIcon = 'amex-icon';
    if (event.target.classList.contains('discover')) this.creditCardIcon = 'discover-icon';
    if (event.target.classList.contains('unknown')) this.creditCardIcon = '';
  }

  countryChange(e) {
    let countryCode = e.value
    if(countryCode == 'US' || countryCode == 'JP' || countryCode == 'CA' || countryCode == 'AU') {
      this.isStateDropDown = true;
      const countryList = this.countryList;
      let country = countryList.find(c => c.abbreviation == countryCode);
      this.states = country.states;
      this.paymentForm.get('state').setValidators([Validators.required]);
      this.paymentForm.get('state').updateValueAndValidity();
    } else {
      this.isStateDropDown = false;
      this.states = [];
      this.paymentForm.get('state').setValidators([]);
      this.paymentForm.get('state').updateValueAndValidity();
    }
  }

  onSubmit() {
    console.log("3DS Upgraded");
    if (this.paymentForm.value.terms == null) { this.termsChecked = true; }
    if (!this.paymentForm.valid || !this.paymentForm.value.terms) return console.log("failed");

    this.paymentForm.value.phone = this.paymentForm.value.phone.replace(/\D+/g, '');
    this.paymentForm.value.creditCard = this.paymentForm.value.creditCard.replace(/\D+/g, '');
    this.paymentForm.value.expirationDate = this.paymentForm.value.expirationDate.replace(/\D+/g, '');
    this.paymentForm.value._csrf = this.hppTrans.csrfToken;
    this.paymentForm.value.sessId = this.hppTrans.sessionId;

    this.showLoader = true;
    this.isSubmit = true;
    if (this.hppTrans.caTransData) {
      this.transService.logCaTran(this.paymentForm.value).subscribe((res:any) => {
        if(res.success && res.message =="logged successfully") {
          //submit the form
          this.paymentAddForm1.nativeElement.submit();
        } else {
          this.processMsg = "Transaction is failed";
          setTimeout(() => {
            this.router.navigate(['/payment/fail/' + this.hppTrans.sessionId]);
          }, 2000);
        }

      })
      //this.paymentAddForm1.nativeElement.submit();
      return console.log("Submitted");
    }
	
	
    if(this.hppTrans.isKountEnabled && !this.hppTrans.is3dEnable) {
      this.transService.processKountRis(this.paymentForm.value).subscribe(async (res:any) => {
        let postData = this.paymentForm.value;
        if(res.kResponse && res.kResponse.risResp) {
          postData.kountRISData = JSON.stringify(res.kResponse);
          if(res.kResponse.kountOmniRes == "Error - Less Omni") {
            this.processMsg = "Transaction is declined";
            this.hppTransService.setKountData("Declined. Suspected Fraud");
            setTimeout(() => {
              this.router.navigate(['/payment/fail/' + this.hppTrans.sessionId]);
            }, 2000);
            return;
          }
        } else {
          postData.kountRISData = "kountRisError";
        }
        this.processTransaction(postData);
      });
    } else if (!this.hppTrans.isKountEnabled && this.hppTrans.is3dEnable) {
      this.process3DSTransaction();
    } else if(this.hppTrans.isKountEnabled && this.hppTrans.is3dEnable) {
      this.transService.processKountRis(this.paymentForm.value).subscribe(async (res:any) => {
        if(res.kResponse && res.kResponse.risResp) {
          this.paymentForm.value.kountRISData = JSON.stringify(res.kResponse);
          if(res.kResponse.kountOmniRes == "Success") {
            this.processTransaction(this.paymentForm.value);
          } else {
            this.process3DSTransaction();
          }
        } else {
          this.paymentForm.value.kountRISData = "kountRisError";
          this.process3DSTransaction();
        }
      });
    } else {
      this.processTransaction(this.paymentForm.value);
    }
  }
  
  
  processTransaction(postData) {
    console.log("3DS Upgraded");
    this.transService.processTrans(postData)
            .subscribe((res: any) => {
              if (Object.keys(res.transaction).length == 0) {
                this.processMsg = "Transaction is failed";
                setTimeout(() => {
                  this.router.navigate(['/payment/fail/' + this.hppTrans.sessionId]);
                }, 2000);
              }
              
              this.hppTransService.setOrginalTransData(res.transaction);
              if (res.transaction.responseCode == '00' || res.transaction.responseCode == '000') {
                this.processMsg = "Transaction is approved";
              } else {
                this.processMsg = "Error with your transaction"; 
              }
              setTimeout(() => {
                this.router.navigate(['/payment/checkout-confim/' + this.hppTrans.sessionId]);
              }, 2000);

            });
  }

  process3DSTransaction() {
    console.log("3DS Upgraded");
    Cardinal.trigger("bin.process", this.paymentForm.value.creditCard);
    let countryObj = this.countries.find(c => c.abbreviation == this.paymentForm.value.country);
    let countryCode = countryObj ? countryObj.code : '';
    let threeDSpostData = {
      jwt:this.hppTrans.jwt,
      cardnumber:this.paymentForm.value.creditCard, 
      ccexpmonth: this.paymentForm.value.expirationDate.substr(0,2),
      ccexpyear:"20"+this.paymentForm.value.expirationDate.substr(2,2), 
      sessionId: this.paymentSetupData.sessionId,
      tokenId: this.hppTrans.sessionId,
      address1: this.paymentForm.value.address1,
      city: this.paymentForm.value.city,
      country: this.paymentForm.value.country,
      firstname: this.paymentForm.value.firstname,
      lastname: this.paymentForm.value.lastname,
      state: this.paymentForm.value.state,
      postal: this.paymentForm.value.postal,
      phone: this.paymentForm.value.phone,
      email: this.paymentForm.value.email,
      countryCode: countryCode
    };
    this.transService.process3DTrans(threeDSpostData).subscribe(async (res: any) => {
      if ((res.responseData.CardinalMPI.AuthenticationPath && res.responseData.CardinalMPI.AuthenticationPath[0] == 'NOREDIRECT') || 
      (res.responseData.CardinalMPI.AuthenticationPath &&res.responseData.CardinalMPI.AuthenticationPath[0] == 'ATTEMPTS_COMPLETE') || res.responseData.CardinalMPI.ACSUrl[0] == '') {
        this.processTransaction(this.paymentForm.value);
      } else {
        Cardinal.continue('cca',
              {
                  "AcsUrl":res.responseData.CardinalMPI.ACSUrl[0],
                  "Payload":res.responseData.CardinalMPI.Payload[0]
              },
              {
                  "OrderDetails":{
                      "TransactionId" :res.responseData.CardinalMPI.TransactionId[0]
                  }
          });
          
        Cardinal.on('ui.close', function(){
          
        });

          let paymentvalData:any =   await this.paymentValidated();
          const decodedResponseData = paymentvalData.decodedResponseData;
          let ActionCode = decodedResponseData.ActionCode;
          if(ActionCode == 'SUCCESS') {
            let postData = this.paymentForm.value;
            postData.jwt = paymentvalData.responseJWT;
            postData.responseData = JSON.stringify(paymentvalData.decodedResponseData);
            this.processTransaction(postData);
          } else {
            this.processMsg = "Transaction is declined";
            setTimeout(() => {
              this.router.navigate(['/payment/fail/' + this.hppTrans.sessionId]);
            }, 2000);
          }
      }
    });
  }

  async getpaymentValidatedData() { 
    return await this.paymentValidated();
  }

  paymentValidated() {
    return new Promise(resolve => {
      Cardinal.on('payments.validated', function(decodedResponseData, responseJWT){
        resolve({decodedResponseData, responseJWT});
      });
    });
  }

  cancelPayment() {
    this.showLoader = true;
    this.isSubmit = true;    
    this.processMsg = "Redirecting to merchant website";
    this.transService.cancelTrans(this.hppTrans.sessionId)
      .subscribe((res: any) => {
        window.location.href=this.hppTrans.returnUrl+'?sessionId='+this.hppTrans.sessionId+'&error=user-has-cancelled';
      });
      /*setTimeout(()=> {
        window.location.href=this.hppTrans.returnUrl+'?sessionId='+this.hppTrans.sessionId+'&error=user-has-cancelled';
      }, 2000);*/
  }

}
