import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TextMaskModule } from 'angular2-text-mask';
import { CreditCardDirectivesModule  } from 'angular-cc-library';


import { AngularMaterialModule } from "./angular-material.module";
import { AppRoutingModule } from "./app-routing.module";
import { HppTransGuard } from "./payment/hpp.trans.guard";
import { HppCaTransGuard } from "./payment/hpp.catrans.guard";
import { ErrorInterceptor } from "./error-interceptor";

import { CheckoutComponent } from './payment/checkout/checkout.component';
import { AuthErrorComponent } from './payment/auth-error/auth-error.component';
import { ErrorComponent } from './error/error.component';
import { TransactionConfirmComponent } from './transaction/transaction-confirm/transaction-confirm.component';
import { TransactionFailureComponent } from './transaction/transaction-failure/transaction-failure.component';
import { InactiveComponent } from './payment/in-active/inactive.component';
import { CheckoutErrorComponent } from './payment/checkout-error/checkout.error.component';
import { CheckoutCaConfirmComponent } from "./payment/checkout-caconfirm/checkout.caconfirm.component";

@NgModule({
  declarations: [
    AppComponent,
    CheckoutComponent,
    AuthErrorComponent,
    ErrorComponent,
    InactiveComponent,
    TransactionConfirmComponent,
    TransactionFailureComponent,
    CheckoutErrorComponent,
    CheckoutCaConfirmComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    TextMaskModule,
    FormsModule,
    CreditCardDirectivesModule
  ],
  providers: [    
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    HppTransGuard,
    HppCaTransGuard
  ],
  bootstrap: [AppComponent],  
  entryComponents: [ErrorComponent]
})
export class AppModule { }
