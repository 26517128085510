import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

import { environment } from "../../environments/environment";
import { HppTransData } from "./hpp.trans-data.model";

const BACKEND_URL = environment.apiUrl + "hpp/checkout/";

@Injectable({ providedIn: "root" })
export class HppTransService {
  private isValidSessId = false;
  private hppTransStatusListener = new Subject<boolean>();
  private hppTransData: any;
  private hppError: any;
  private orginalTransData: any;
  private kountResData: any;
  private transactionTimer: any;
  private inActiveTime: number = environment.inactiveTime;

  constructor(private http: HttpClient, private router: Router) {}

  getIsValidSessId() {
    return this.isValidSessId;
  }

  getHppTransData() {
    return this.hppTransData;
  }

  getHppError() {
    return this.hppError;
  }

  getOrginalTransData() {
    return this.orginalTransData;
  }

  getKountResData() {
    return this.kountResData;
  }

  setOrginalTransData(trans:any) {
    this.orginalTransData = trans;
  }

  setKountData(trans:any) {
    this.kountResData = trans;
  }

  setHppError(err:any) {
    this.hppError = err;
  }

  getHppTransStatusListener() {
    return this.hppTransStatusListener.asObservable();
  } 

  getHppTrans(sessId: string) {
    return this.http
      .get(
        BACKEND_URL + sessId,
      )
  }

  clearHppTrans(sessId) {
    this.isValidSessId = false;
    this.hppTransData = {};
    this.hppTransStatusListener.next(false);
    clearTimeout(this.transactionTimer);
    this.http.get(BACKEND_URL + sessId + '?timeout=1')
    .subscribe((response: any)=>{
       if (response.success) this.router.navigate(["/payment/checkout-inactive"]);
    });
  }

  public setAuthTimer(sessId) {
    let duration = this.inActiveTime;
    console.log("Setting timer: " + duration);
    this.transactionTimer = setTimeout(() => {
      this.clearHppTrans(sessId);
    }, duration * 1000);
  }

  public setData(hppTrans) {    
    this.hppTransData = hppTrans;
    this.isValidSessId = true;
  }
}
