declare var require: any;
import { Component, OnInit } from '@angular/core';

import { HppTransService } from 'src/app/payment/hpp.trans.service';

@Component({
  selector: 'app-transaction-failure',
  templateUrl: './transaction-failure.component.html',
  styleUrls: ['./transaction-failure.component.css']
})
export class TransactionFailureComponent implements OnInit {

  imgname = require("../../../assets/images/caymangatewaylogo340.png");
  transData: any;
  transid: string;
  hppTransData: any;
  kountResData: any;
  returnUrl: string;

  constructor(public hppTransService: HppTransService) { }

  ngOnInit() {
    this.hppTransData = this.hppTransService.getHppTransData();
    this.kountResData = this.hppTransService.getKountResData();

    this.returnUrl = this.hppTransData.returnUrl+'?sessId='+this.hppTransData.sessionId+'&error=true';
    this.transid = this.hppTransData.sessionId;
  }
}
